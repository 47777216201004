import _ from 'lodash'

function getBrowserAgent () {
    return _.get(navigator, 'userAgent', null) ||
    _.get(navigator, 'vendor', null) ||
    _.get(window, 'opera', null)
}
function isiOS (userAgent) {
    return /iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream
}
function isAndroid (userAgent) {
    return /Android/i.test(userAgent)
}
function isMobile (userAgent) {
    return isiOS(userAgent) || isAndroid(userAgent)
}

export {
    getBrowserAgent,
    isMobile,
    isAndroid,
    isiOS
}
