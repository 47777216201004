import Vue from 'vue'
import App from './App.vue'
import router from './router'

import HunyVerseStyle from '@/assets/scss/hunyverse.styl'
import i18n from './plugins/i18n'

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    render: (h) => h(App)
}).$mount('.application')
